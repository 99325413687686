import React from 'react';
import dynamic from 'next/dynamic';
import { withRouter } from 'next/router';
import withApollo from '../lib/apollo';
import loading from '../lib/loading';
import redirectRouteToDomain from '../lib/redirectRouteToDomain';

const FashionWeekWeb = dynamic(() => import('../components/FashionWeekWeb'), {
  ssr: true,
  loading,
});

const FashionWeekWebPage = () => (<FashionWeekWeb />);

FashionWeekWebPage.getInitialProps = redirectRouteToDomain(
  'fashion-week-web',
);

export default withApollo(withRouter(FashionWeekWebPage));
